export const navigationPrimary = [
  {
    name: "About Us",
    icon: "fas fa-folder",
    collapse: [
      { name: "About Page", route: "aboutus", icon: "fas fa-address-card" },
      {
        name: "About Us Craft",
        route: "aboutus-craft",
        icon: "fas fa-indent",
      },
      {
        name: "About Us Independent",
        route: "aboutus-independent",
        icon: "fas fa-indent",
      },
      {
        name: "About Us Small",
        route: "aboutus-small",
        icon: "fas fa-indent",
      },
    ],
  },
  { name: "Beer List", route: "beer", icon: "fas fa-address-card" },
  { name: "Beer Page", route: "beer-page", icon: "fas fa-address-card" },
  { name: "Special List", route: "special", icon: "fas fa-address-card" },
  { name: "Cider List", route: "cider", icon: "fas fa-address-card" },
  { name: "Cup List", route: "cup", icon: "fas fa-address-card" },
  { name: "Cup Page", route: "cup-page", icon: "fas fa-address-card" },
  {
    name: "Blog",
    icon: "fas fa-folder",
    collapse: [
      { name: "Blog page", route: "blog", icon: "fas fa-address-card" },
      { name: "Blog post", route: "blog-post", icon: "fas fa-indent" },
    ],
  },
  {
    name: "Brewery",
    icon: "fas fa-folder",
    collapse: [
      { name: "Brewery page", route: "brewery", icon: "fas fa-address-card" },
      {
        name: "Brewery process",
        route: "brewery-process",
        icon: "fas fa-indent",
      },
      {
        name: "Brewery ingridient",
        route: "brewery-ingridient",
        icon: "fas fa-indent",
      },
    ],
  },
  {
    name: "Brewpub",
    icon: "fas fa-address-card",
    collapse: [
      { name: "Brewpub page", route: "brewpub", icon: "fas fa-address-card" },
    ],
  },
  {
    name: "Contacts",
    icon: "fas fa-folder",
    collapse: [
      { name: "Contact Page", route: "contact-us", icon: "fas fa-address-card" },
      { name: "Contact Brewery", route: "contact-brewery", icon: "fas fa-address-card" },
      { name: "Contact Brewpub", route: "contact-brewpub", icon: "fas fa-address-card" },
    ],
  },
  {
    name: "Partnership",
    icon: "fas fa-folder",
    collapse: [
      {
        name: "Partnership Page",
        route: "partnership",
        icon: "fas fa-address-card",
      },
      { name: "Partners", route: "partner", icon: "fas fa-indent" },
    ],
  },
  {
    name: "MAPS",
    icon: "fas fa-folder",
    route: "map",
  },
];
export const navigationSecondary = [
  { name: "Media", route: "media", icon: "fas fa-photo-video" },
  { name: "Map Type", route: "map-type", icon: "fas fa-address-card" },
  {
    name: "Privacy Policy",
    route: "privacy-policy",
    icon: "fas fa-user-secret",
  },
  {
    name: "Terms Of Service",
    route: "terms-of-service",
    icon: "fas fa-id-badge",
  },
];
