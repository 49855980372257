import React, { useState, useEffect } from "react";
// import './adminlte.css';
import "./scss/adminlte.scss";

// Helpers
import history from "./helpers/history";
import { cookies, setCookies } from "./helpers/cookies";
import { isLoggedIn } from "./helpers/auth";
import API from "./helpers/api";
import { useStateWithLocalStorage } from "./helpers/storage";
import config from "./config";
import Loading from "./components/modules/loading.jsx";

// global components
import Login from "./components/login";
import Main from "./components/main/main";

//Routing
import { Router, Route, Switch } from "react-router-dom";

// Components
const AboutUs = React.lazy(() => import("./components/about-us/aboutUs")); // Done
const AboutUsCraft = React.lazy(() =>
  import("./components/about-us-craft/aboutUsCraft")
); // Done
const AboutUsIndependent = React.lazy(() =>
  import("./components/about-us-independent/aboutUsIndependent")
); // Done
const AboutUsSmall = React.lazy(() =>
  import("./components/about-us-small/aboutUsSmall")
); // Done
const Cup = React.lazy(() => import("./components/cups/cup")); // has an image
const CupList = React.lazy(() => import("./components/cups/cups"));
const CupPage = React.lazy(() => import("./components/cup-page/cupPage")); // has an image
const Blog = React.lazy(() => import("./components/blog/blog")); // Done
const BlogPost = React.lazy(() => import("./components/blog-post/blogPost")); // has an image // Done
const BlogPosts = React.lazy(() => import("./components/blog-post/blogPosts"));
const Brewery = React.lazy(() => import("./components/brewery/brewery"));
const BreweryIngridient = React.lazy(() =>
import("./components/brewery-igridient/breweryIngridient")
); // has an image
const BreweryIngridients = React.lazy(() =>
import("./components/brewery-igridient/breweryIngridientList")
); // has an image
const BreweryProcess = React.lazy(() =>
import("./components/brewery-process/breweryProcess")
); // has an image
const BreweryProcesses = React.lazy(() =>
import("./components/brewery-process/breweryProcessList")
); // has an image
const Brewpub = React.lazy(() => import("./components/brewpub/brewpub"));
const Contactus = React.lazy(() => import("./components/contact-us/contactUs"));
const ContactBrewery = React.lazy(() => import("./components/contact-brewery/contactBrewery"));
const ContactBrewpub = React.lazy(() => import("./components/contact-brewpub/contactBrewpub"));
const Media = React.lazy(() => import("./components/media/media"));
const Beer = React.lazy(() => import("./components/beer/beer")); // has an image
const BeerList = React.lazy(() => import("./components/beer/beerList"));
const BeerPage = React.lazy(() => import("./components/beer-page/beerPage")); // has an image
const Special = React.lazy(() => import("./components/special/special")); // has an image
const SpecialList = React.lazy(() =>
  import("./components/special/specialList")
);
const Cider = React.lazy(() => import("./components/cider/cider")); // has an image
const CiderList = React.lazy(() => import("./components/cider/ciderList"));
const Partnership = React.lazy(() =>
  import("./components/partnership/partnership")
);
const Partner = React.lazy(() => import("./components/partner/partner")); // has an image
const Partners = React.lazy(() => import("./components/partner/partnerList"));
const MapType = React.lazy(() => import("./components/map-type/mapType")); // has an image
const MapTypes = React.lazy(() => import("./components/map-type/mapTypes"));
const Policy = React.lazy(() => import("./components/policy/policy"));
const Terms = React.lazy(() => import("./components/terms/terms"));

const Map = React.lazy(() => import("./components/maps/map"));
const Maps = React.lazy(() => import("./components/maps/maps"));

function RouterComponent() {
  // window.scrollTo(0, 0);
  return (
    <React.Suspense fallback={<Loading />}>
      <Route path="/" component={AboutUs}>
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/aboutus-craft" component={AboutUsCraft} />
        <Route path="/aboutus-independent" component={AboutUsIndependent} />
        <Route path="/aboutus-small" component={AboutUsSmall} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/blog-post/:id" component={BlogPost} />
        <Route exact path="/blog-post" component={BlogPosts} />
        <Route exact path="/brewery" component={Brewery} />
        <Route exact path="/brewery-process/:id" component={BreweryProcess} />
        <Route exact path="/brewery-process" component={BreweryProcesses} />
        <Route
          exact
          path="/brewery-ingridient"
          component={BreweryIngridients}
        />
        <Route
          exact
          path="/brewery-ingridient/:id"
          component={BreweryIngridient}
        />
        <Route exact path="/brewpub" component={Brewpub} />
        <Route exact path="/cup-page" component={CupPage} />
        <Route exact path="/cup" component={CupList} />
        <Route exact path="/cup/:id" component={Cup} />
        <Route exact path="/contact-us" component={Contactus} />
        <Route exact path="/contact-brewery" component={ContactBrewery} />
        <Route exact path="/contact-brewpub" component={ContactBrewpub} />
        <Route exact path="/media" component={Media} />
        <Route exact path="/beer" component={BeerList} />
        <Route exact path="/beer/:id" component={Beer} />
        <Route exact path="/beer-page" component={BeerPage} />
        <Route exact path="/special" component={SpecialList} />
        <Route exact path="/special/:id" component={Special} />
        <Route exact path="/cider" component={CiderList} />
        <Route exact path="/cider/:id" component={Cider} />
        <Route exact path="/partnership" component={Partnership} />
        <Route exact path="/partner" component={Partners} />
        <Route exact path="/partner/:id" component={Partner} />
        <Route exact path="/privacy-policy" component={Policy} />
        <Route exact path="/terms-of-service" component={Terms} />

        <Route exact path="/map" component={Maps} />
        <Route exact path="/map/:id" component={Map} />
        <Route exact path="/map-type" component={MapTypes} />
        <Route exact path="/map-type/:id" component={MapType} />
      </Route>
    </React.Suspense>
  );
}
function App() {
  let [loggedIn, setLoggedIn] = useState(Boolean);
  // eslint-disable-next-line
  let [username, setUsername] = useStateWithLocalStorage("username");

  async function logout() {
    await setCookies("");
    await setUsername("");
    await setLoggedIn(false);
  }

  function login(username, password) {
    if (username && password) {
      return API.post(config.authentification.login, {
        username,
        password,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(res.data.token);
            setUsername(JSON.stringify(res.data.user.name));
            setLoggedIn(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoggedIn(false);
        });
    } else {
      setLoggedIn(false);
      console.log("There is no user with provided username & password");
    }
  }

  async function checkToken() {
    let token = await cookies.get(config.authentification.tokenAddress);
    if (token) {
      API.post(config.authentification.verify, {
        token,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(token);
            setLoggedIn(true);
          } else {
            setLoggedIn(false);
          }
        })
        .catch((res) => {
          console.error(res);
          console.error(`Please don't edit cookies manually`);
          cookies.remove("authorization");
        });
    } else {
      setLoggedIn(false);
      console.log(
        "You must provide token for calling login -> veryfyToken functions, Or mongodb database is not running"
      );
    }
  }
  useEffect(() => {
    (async () => {
        await checkToken();
    })();
}, [loggedIn]);

  if (loggedIn) {
    return (
            <isLoggedIn.Provider
                value={{ login, logout, loggedIn }}>
                <Router history={history}>
                    <Switch>
                        <Main component={RouterComponent} />
                    </Switch>
                </Router>
            </isLoggedIn.Provider>
    );
} else {
    return (
        <isLoggedIn.Provider
        value={{ login, logout, loggedIn }}>
            <Router history={history}>
                <Switch>
                    <Route component={Login} />
                </Switch>
            </Router>
        </isLoggedIn.Provider>
    );
}
}

export default App;
