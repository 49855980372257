const config = {
    api: {
        aboutus: "aboutus",
        aboutusCraft: "aboutus-craft",
        aboutusIndependent: "aboutus-independent",
        aboutusSmall: "aboutus-small",
        beer: "beer",
        beerPage: "beer-page",
        special: "special",
        cider: "cider",
        cup: "cup",
        cupPage: "cup-page",
        blog: "blog",
        news: "news",
        blogPost: "blog-post",
        brewery: "brewery",
        breweryIngridient: "brewery-ingridient",
        breweryProcess: "brewery-process",
        brewpub: "brewpub",
        contactus: "contactus",
        contactBrewery: "contact-brewery",
        contactBrewpub: "contact-brewpub",
        partnership: "partnership",
        partner: "partner",
        privacyPolicy: "privacy-policy",
        termsOfService: "terms-of-service",
        map: "map",
        mapType: "map-type",

        uploads: "uploads",
        API_URL: process.env.REACT_APP_API_URL,
        MAINAPP_URL: process.env.REACT_APP_MAINAPP_URL,
        API_Version: "v1"
    },
    authentification: {
        login: "/login/private",
        verify: "/verify",
        tokenAddress: "private-token"
    },
    brand: {
        name: "dargett.com",
        link: "https://dargett.com",
        logo: "/logo.png"
    },
    genders: [
        {
            name: {
                en: "Male",
                ru: "Мужчина",
                hy: "Արական"
            }
        }, {
            name: {
                en: "Female",
                ru: "Женщина",
                hy: "իգական"
            }
        },
    ],
    swal: {
        emailNotValid: "Email is not valid"
    }
};

export default config;
