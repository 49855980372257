import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import Navbar from "./navbar";

import Loading from "../modules/loading";
import Footer from "./footer";

export default function Main({ component: Component, ...rest }) {
  let [isBusy, setIsBusy] = useState(true);
  const [open, setOpen] = useState(false);
  const setOpenSidebar = () => {
    setOpen(false);
    document.body.classList.remove("sidebar-close");
    document.body.classList.add("sidebar-open");
    document.body.classList.remove("sidebar-collapse");
  };
  const setCloseSidebar = () => {
    setOpen(true);
    document.body.classList.add("sidebar-collapse");
    document.body.classList.remove("sidebar-open");
    document.body.classList.add("sidebar-close");
  };

  const toggleSidebar = () => {
    if (open) setOpenSidebar();
    else setCloseSidebar();
  };

  useEffect(() => {
    (async () => {
      await setIsBusy(false);
      toggleSidebar();
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) {
    return <Loading />;
  } else {
    return (
      <>
        <Navbar settings={{ toggleSidebar }} />
        <Sidebar />
        <div className="content-wrapper">
          <Component />
        </div>
        <div id="sidebar-overlay" onClick={toggleSidebar}></div>
        <Footer />
      </>
    );
  }
}
