import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../helpers/auth";

export default function Login() {
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  const { loggedIn, login } = useAuth();
  const [open, setopen] = useState(false);

  const toggleAlert = () => {
    setopen(true);
    setTimeout(() => {
      setopen(false);
    }, 3000);
  };

  function handleUsernameChange(event) {
    setUsername(event.target.value);
  }
  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    login(username, password);
    if (loggedIn === false) setTimeout(() => toggleAlert(), 0);
    setTimeout(() => toggleAlert(), 3500);
  }

  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div className={`alert ${open ? "show" : ""}`}>
        <div className="toast bg-danger fade show">
          <div className="toast-body">
            You entered the wrong login or password
          </div>
        </div>
      </div>

      <div className="login-box">
        <div className="login-logo">
          <a href="/">
            <b>Dargett</b> Admin
          </a>
        </div>

        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Log in to start your session</p>

            <form onSubmit={handleSubmit} method="post">
              <div className="input-group mb-3">
                <input
                  type="username"
                  className="form-control"
                  placeholder="username"
                  value={username}
                  onChange={handleUsernameChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="password"
                  autoComplete="on"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <button type="submit" className="btn btn-primary btn-block" aria-label="login">
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
