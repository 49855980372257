import React from "react";

export default function Navbar(props) {
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <span
            className="nav-link"
            data-widget="pushmenu"
            onClick={props.settings.toggleSidebar}
            role="button"
          >
            <i className="fas fa-bars"></i>
          </span>
        </li>
      </ul>
    </nav>
  );
}
